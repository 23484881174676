<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Ist das ein Geschäft?</h1>
      <p>
        Die kurze Antwort lautet: Es könnte eines werden, aber ist es im Moment nicht.
      </p>
      <p>
        Seit wir das Land Ende 2017 gekauft haben, ist dies eine Geldsenke. Für das Land wurde eine beachtliche Summe
        bezahlt, es gibt Vollzeitbeschäftigte, Tiere wurden gekauft und mit gekauftem Futter gefüttert, da das Land
        aufgrund einer jährlichen Dürre von etwa 6 Monaten nur einen Bruchteil des Jahres Futter zu liefern in der Lage
        ist. Es ist so, dass das Land nicht in der Lage wäre viele Tiere zu ernähren. Das Land ist beschädigt und
        wir reparieren es.
      </p>
      <p>
        Die Mittel zur Finanzierung des laufenden Betriebs und der Investitionen stammen aus der Arbeit im IT-Bereich.
        Wir haben jedoch vom Land gegessen. Bisher haben wir einige Schweine und sogar Rinder zu unserem eigenen Essen
        verarbeitet. Derzeit ist unser Hauptziel, <em>Netto-Null</em> in der Bilanz zu erreichen.
      </p>

      <h2>Zukünftige Produkte</h2>
      <p>
        Um das Ziel von <em>Netto-Null</em> zu erreichen, suchen wir nach Möglichkeiten einige Produkte ohne
        Widerspruch der Projektziele anzubieten. Es ist hauptsächlich und in erster Linie ein Projekt, um
        Überfluss in Boden, Flora, Fauna und bei den Pilze zu erschaffen. Den Überschuss können wir teilen.
      </p>
      <p>
        Das bedeutet, dass jedes Produkt, das wir haben werden, besonders und knapp sein wird - alles abhängig
        von der Entwicklung des Bodens, aus dem es entstanden ist.
      </p>

      <YouTubeLink />

    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "BusinessDE",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
